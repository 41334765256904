/* Gallery.css */

.slick-prev:before,
.slick-next:before {
    color: black; /* Change the arrow color to black */
}

.custom-arrow.slick-prev,
.custom-arrow.slick-next {
    font-size: 30px; /* Adjust the size of the arrows if needed */
    z-index: 1;
}

.custom-arrow.slick-prev {
    left: -25px; /* Adjust position as needed */
}

.custom-arrow.slick-next {
    right: -25px; /* Adjust position as needed */
}
